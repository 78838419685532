<template>
  <div class="wrap">
    <div class="search">
      <input
        type="text"
        class="searchTerm"
        :placeholder="placeHolder"
        v-model="searchTerm"
      />
      <button type="submit" class="searchButton">
        <span
          class="iconify"
          data-icon="akar-icons:search"
          data-inline="false"
        ></span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchBar",
  emits: ["searched"],
  props: ["placeHolder"],
  data() {
    return {
      searchTerm: ""
    };
  },
  watch: {
    searchTerm: function() {
      this.$emit("searched", this.searchTerm);
    }
  },
  methods: {
    Searched() {}
  }
};
</script>

<style scoped>
@import url(https://fonts.googleapis.com/css?family=Open+Sans);

body {
  width: 100px;
  background: #f2f2f2;
  font-family: "Open Sans", sans-serif;
}

.search {
  width: 100%;
  position: relative;
  display: flex;
}

.searchTerm {
  width: 100%;
  border: 2px solid red;
  border-right: none;
  padding: 5px;
  height: 50px;
  border-radius: 2px 0 0 5px;
  outline: none;
  color: #9dbfaf;
}

.searchTerm:focus {
  color: red;
}
.searchButton:focus {
  outline-color: crimson;
}
.searchButton {
  padding: 5px;
  height: 50px;
  width: 64px;
  transition: 0.3s;
  border: 2px solid red;
  background: rgb(242, 227, 227);
  text-align: center;
  color: rgb(148, 22, 0);
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}
.searchButton:hover {
  background-color: rgb(148, 22, 0);
  color: white;
}

/*Resize the wrap to see the search bar change!*/
.wrap {
  max-width: 700px;

  margin: 0 auto;
}
</style>
